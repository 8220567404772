import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import "./vue-good-table-custom.css";

export default {
    components: {
        VueGoodTable
    },
    mixins: [],
    props: {},
    data: function () {
        return {
            perPageOptions: [15, 30, 50, 75, 100]
        };
    },
    computed: {},
    watch: {},
    created: function () {},
    beforeDestroy: function () {},
    mounted: function () {},
    methods: {}
};
